import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Language from './Language.data';
import EmailAddress from './EmailAddress.component';
import ExternalLink from './ExternalLink.component';
import styled from 'styled-components';
import * as Tracking from '../utils/matomo';
import { phoneNumberToLink } from '../utils/utils';
import { FormattedText } from '../styles/FormattedText.styles';
import PodcastAdvertisingSpace from './PodcastAdvertisingSpace.component';
import { COUNTRY_GREAT_BRITAIN } from '../constants/countries';

import phone from '../images/phoneWhite.svg';
import fax from '../images/faxWhite.svg';
import email from '../images/emailWhite.svg';
import instagram from '../images/socialmedia_instagram.png';
import linkedin from '../images/socialmedia_linkedin.png';
import facebook from '../images/socialmedia_facebook.png';
import ecovadis from '../images/ecovadisPlatinum.webp';
import cbaAwardsBadge from '../images/cba_awards_winner_badge.webp';
import '../css/footer.css';

const FooterColumn = styled.div`
    text-align:${props => props.textAlign ? props.textAlign : 'center'};
    overflow:hidden;

    @media (max-width: 768px) { 
        text-align:center;
    }
`

const EcovadisImage = styled.img`
    /*
    max-height:100px;
    height:100%;
    */
   width:100%;
   max-width:100px;
`


const CbaAwardImage = styled.img`
   width:100%;
   max-width:95px;
`

const FooterDiv = styled.footer`
    @media (max-width: 576px) { 
        position:relative;
        left:-3px;
        width:calc(100% + 6px);
    }
`

const FooterContainerDiv = styled.div`
    border-radius:0px;
`

const FooterInnerContainerDiv = styled.div`
    background-color:transparent;
`

const WhiteSpaceNoWrapDiv = styled.div`
    white-space:nowrap;
`

function Footer(props) {

    function onLinkClick(event,clickType) {
        Tracking.trackEvent('General','Click Footer ' + clickType, 'Footer ' + clickType);
    }

    function handlePodcastClick() {
        Tracking.trackEvent('General','Click Podcast','Podcast overlay link');
    }

    return (
        <FooterDiv className="footer">
            <PodcastAdvertisingSpace 
                key="podcastAdvertiseFooter"
                currentLanguage={props.currentLanguage}
                isOverlay={false}
            />
            <FooterContainerDiv className="container-fluid borderTop1px footerLayout">
                <div className="row">
                    <div className="col-12">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <FooterInnerContainerDiv className="container-fluid">
                                    <div className="row">
                                        <FooterColumn 
                                            textAlign="left"
                                            className="col-12 col-md-3 order-1"
                                        >
                                            <address>
                                                <small>
                                                    <strong>{props.country.name[props.currentLanguage].toUpperCase()}</strong>
                                                    <br />
                                                    <address>
                                                        <span dangerouslySetInnerHTML={{__html: props.country.address}}></span>
                                                    </address>
                                                </small>
                                            </address>
                                        </FooterColumn>
                                        <FooterColumn className="col-12 col-md-6 mb-4 text-center order-3 order-md-2">
                                            <div className="row">
                                                <div className="d-none d-lg-block col-lg-2 text-center">
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                                    <Link to="/resources" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Resources')}><small>{Language[props.currentLanguage].resources}</small></Link>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                                    <Link to="/imprint" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Imprint')}><small>{Language[props.currentLanguage].imprint}</small></Link>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                                    <Link to="/privacy" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Privacy')}><small>{Language[props.currentLanguage].privacy}</small></Link>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                                    <Link to="/contact" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Contact')}><small>{Language[props.currentLanguage].contact}</small></Link>
                                                </div>
                                                <div className="d-none d-lg-block col-lg-2 text-center">
                                                </div>
                                            </div>
                                            <div className="row d-xs-block d-lg-none">
                                                <div className="col">
                                                    <ExternalLink
                                                        {...props}
                                                        key={"socialMediaFooterFacebook"}
                                                        id={"socialMediaFooterFacebook"}
                                                        linkHrefTag={"https://www.facebook.com/grolmangroup" }
                                                        linkTargetTag={"_blank"}
                                                        linkAdditionalClasses={"footerLink"}
                                                        imageSrcTag={facebook}
                                                        imageAltTag={"Follow us on Facebook"}
                                                        imageWidth={"25px"}
                                                        imageMinWidth={"25px"}
                                                        iconWidth={"16px"}
                                                        iconHeight={"16px"}
                                                        iconPositonRight={"-7px"}
                                                        iconPositonBottom={"-7px"}
                                                        maskColor={"#fff"}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <ExternalLink
                                                        {...props}
                                                        key={"socialMediaFooterInstagram"}
                                                        id={"socialMediaFooterInstagram"}
                                                        linkHrefTag={"https://www.instagram.com/grolmangroup/" }
                                                        linkTargetTag={"_blank"}
                                                        linkAdditionalClasses={"footerLink"}
                                                        imageSrcTag={instagram}
                                                        imageAltTag={"Follow us on Instagram"}
                                                        imageWidth={"25px"}
                                                        imageMinWidth={"25px"}
                                                        iconWidth={"16px"}
                                                        iconHeight={"16px"}
                                                        iconPositonRight={"-7px"}
                                                        iconPositonBottom={"-7px"}
                                                        maskColor={"#fff"}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <ExternalLink
                                                        {...props}
                                                        key={"socialMediaFooterLinkedIn"}
                                                        id={"socialMediaFooterLinkedIn"}
                                                        linkHrefTag={"https://www.linkedin.com/company/grolmangroup" }
                                                        linkTargetTag={"_blank"}
                                                        linkAdditionalClasses={"footerLink"}
                                                        imageSrcTag={linkedin}
                                                        imageAltTag={"Follow us on LinkedIn"}
                                                        imageWidth={"25px"}
                                                        imageMinWidth={"25px"}
                                                        iconWidth={"16px"}
                                                        iconHeight={"16px"}
                                                        iconPositonRight={"-7px"}
                                                        iconPositonBottom={"-7px"}
                                                        maskColor={"#fff"}
                                                    />
                                                </div>
                                            </div>
                                        </FooterColumn>
                                        <FooterColumn 
                                            textAlign="right"
                                            className="mb-4 col-12 col-md-3 order-2 order-md-3"
                                        >
                                            <div className="row">
                                                <div className="col-12 col-lg-12">
                                                    {
                                                        (props.country.phone !== undefined && props.country.phone.length > 0) &&
                                                            <div className="row">
                                                                <div className="col-12 overflowHidden">
                                                                    <img src={phone} alt="Anrufen" className="phone"></img>
                                                                    <span> </span>
                                                                    <small><a className="footerLink" href={phoneNumberToLink(props.country.phone)}>{props.country.phone}</a></small>
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        (props.country.fax !== undefined && props.country.fax.length > 0) &&
                                                            <div className="row">
                                                                <div className="col-12 overflowHidden"> 
                                                                    <img src={fax} alt="Fax" className="phone"></img> 
                                                                    <span> </span>
                                                                    <small><a className="footerLink" href={phoneNumberToLink(props.country.fax)}>{props.country.fax}</a></small>
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        (props.country.email !== undefined && props.country.email.length > 0) &&
                                                            <WhiteSpaceNoWrapDiv className="row">
                                                                <div className="col-12 overflowHidden"> 
                                                                    <img src={email} alt="Email" className="footerEmailImage"></img>
                                                                    <span> </span>
                                                                    <small>
                                                                        <EmailAddress key={"emailAddressKey" + props.country.name[props.currentLanguage]} classNames="footerLink" emailAddress={props.country.email} />
                                                                    </small>
                                                                </div>
                                                            </WhiteSpaceNoWrapDiv>
                                                    }
                                                </div>

                                                
                                            </div>
                                        </FooterColumn>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col d-lg-none text-center">
                                            <EcovadisImage src={ecovadis} alt="Ecovadis" />
                                        </div>
                                        {
                                            props.country.countryNo === COUNTRY_GREAT_BRITAIN &&
                                            <div className="col d-lg-none text-center">
                                                <CbaAwardImage src={cbaAwardsBadge} alt="CBA Awards Winner" />
                                            </div>
                                        }
                                    </div>
                                </FooterInnerContainerDiv>
                            </div>
                            <div className="col-2">
                                <div className="row">
                                    <div className="d-none d-lg-block col-6">
                                        <EcovadisImage src={ecovadis} alt="Ecovadis" />
                                    </div>
                                    {
                                        props.country.countryNo === COUNTRY_GREAT_BRITAIN &&
                                        <div className="d-none d-lg-block col-6">
                                            <CbaAwardImage src={cbaAwardsBadge} alt="CBA Awards Winner" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </FooterContainerDiv>
        </FooterDiv>
    );
}

export default Footer;